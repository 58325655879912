import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

import Logo from '../../images/logo.png';

const LogoImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 64px;
`;

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 24px;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const DebugDl = styled.dl`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  dt {
    font-weight: bold;
  }

  dt,
  dd {
    margin: 0;
    margin-bottom: 16px;
    width: 50%;
  }
`;

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = () => {
  const [isDone, setIsDone] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [customerId, setCustomerId] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirectUrl');
    const customerId = urlParams.get('customerId');
    setRedirectUrl(redirectUrl);
    setCustomerId(customerId);
    setIsDone(true);
  }, []);

  if (!isDone) return null;

  return (
    <AppContainer>
      <LogoImage src={Logo} />
      {process.env.NODE_ENV === 'development' ? (
        <DebugDl>
          <dt>CustomerId</dt>
          <dd>{customerId}</dd>

          <dt>Redirect Url</dt>
          <dd>{redirectUrl}</dd>
        </DebugDl>
      ) : null}
    </AppContainer>
  );
};

export default Home;
