import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

interface ActiveProps {
  isActive?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #0e2943;
`;

export const LogoImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 24px;
`;

export const IntroText = styled.p`
  margin-bottom: 40px;
`;

export const EntryWrapper = styled.div`
  width: 100%;
  .StripeElement {
    display: block;
    margin: 24px 0 16px 0;
    min-width: 200px;
    /* padding: 10px 14px; */
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
`;

export const BuyButton = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #39c172;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 0;
  margin-top: 32px;
  border-radius: 40px;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
`;
const spin = keyframes`
  100% { transform:rotate(360deg); }
`;
export const LoadingImage = styled.img`
  width: 48px;
  animation: ${spin} 4s linear infinite;
`;

export const CardWrapper = styled.div<ActiveProps>`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 16px;
  background-color: white;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.66')};
  &[aria-checked='true'] {
    border: 1px solid #0e2943;
    padding: 7px 15px;
  }
`;

export const NewCardWrapper = styled(CardWrapper)`
  flex-direction: column;
`;
export const NewCardSelection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
`;

export const CardImage = styled.img`
  height: 40px;
  margin-right: 16px;
`;

export const CardLast4 = styled.div`
  font-weight: bold;
  flex-grow: 1;
`;
export const CardDate = styled.div``;
export const CardText = styled.p`
  margin: 0;
  margin-left: 4px;
  font-size: 18px;
`;

export const SelectionCircle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: white;
  border: 1px solid #0e2943;
  margin-right: 16px;
`;
export const SelectedCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 8px;
  background-color: #0e2943;
  z-index: 1;
`;
