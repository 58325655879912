import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

import Logo from '../../images/logo.png';
import Confirmed from '../../images/confirmed.svg';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #d8fbd944;
`;

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px 24px;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const SuccessMessage = styled.p`
  color: #126f55;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: 0;
`;

const LogoImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 64px;
`;

const ConfirmedImage = styled.img`
  width: 100%;
  max-width: 400px;
  margin-bottom: 64px;
`;

const AccountSuccessPage = ({ redirectUrl, accountId }) => {
  return (
    <Wrapper>
      <AppContainer>
        <LogoImage src={Logo} />
        <ConfirmedImage src={Confirmed} />
        <SuccessMessage>
          Yeah! Wir gratulieren Dir. Du bist ein wichtiger Teil der DropFriends-Community. Dein Auszahlungsmittel wurde erfolgreich
          hinterlegt und wird für die zukünftigen Vergütungsauszahlungen berücksichtigt. Schau direkt in der DropFriends-App vorbei und
          schau nach, ob Deine Annahmestellen-Fotos aktuell sind. So ist es für Deine DropFriends noch leichter Sendungen bei Dir abzuholen.
          Du kannst dieses Fenster nun schließen.
        </SuccessMessage>
      </AppContainer>
    </Wrapper>
  );
};

interface AccountSuccessProps extends RouteComponentProps {}
const AccountSuccess: React.FC<AccountSuccessProps> = () => {
  const [isDone, setIsDone] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [accountId, setAccountId] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirectUrl');
    const accountId = urlParams.get('accountId');
    setRedirectUrl(redirectUrl);
    setAccountId(accountId);
    setIsDone(true);
  }, []);

  if (!isDone) return null;

  return <AccountSuccessPage redirectUrl={redirectUrl} accountId={accountId} />;
};

export default AccountSuccess;
