import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

import Logo from '../../images/logo.png';
import Confirmed from '../../images/confirmed.svg';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #d8fbd944;
`;

const AppContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px 24px;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const SuccessMessage = styled.p`
  color: #126f55;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
`;

const LogoImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 24px;
`;

const ConfirmedImage = styled.img`
  width: 100%;
  max-width: 240px;
  margin-bottom: 48px;
`;
export const BuyButton = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #39c172;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 0;
  margin-top: 32px;
  border-radius: 40px;
`;

const PaypalSuccessPage = ({ payerId, token, redirectUrl }) => {
  const handleBuy = () => {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'Paypal', token }));
    } else {
      window.location.href = `${redirectUrl}paymentId=${token}`;
    }
  };

  return (
    <Wrapper>
      <AppContainer>
        <LogoImage src={Logo} />
        <ConfirmedImage src={Confirmed} />
        <SuccessMessage>
          Dein Zahlungswunsch wurde von PayPal entgegengenommen. Tippe auf "jetzt bezahlen", um Deine Zahlung zu bestätigen und wieder zu
          DropFriends zu gelangen.
        </SuccessMessage>
        <BuyButton onClick={handleBuy}>jetzt bezahlen</BuyButton>
      </AppContainer>
    </Wrapper>
  );
};

interface PaypalSuccessProps extends RouteComponentProps {}

const PaypalSuccess: React.FC<PaypalSuccessProps> = () => {
  const [isDone, setIsDone] = useState(false);
  const [PayerID, setPayerID] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirectUrl');
    const PayerID = urlParams.get('PayerID');
    const token = urlParams.get('token');

    setRedirectUrl(redirectUrl);
    setPayerID(PayerID);
    setToken(token);
    setIsDone(true);
  }, []);

  if (!isDone) return null;

  return <PaypalSuccessPage payerId={PayerID} token={token} redirectUrl={redirectUrl} />;
};

export default PaypalSuccess;
