import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { StripeProvider } from 'react-stripe-elements';
import { Router } from '@reach/router';

import { client } from './client';
import Home from './routes/Home';
import Card from './routes/Card';
import PaypalSuccess from './routes/PaypalSuccess';
import PaypalError from './routes/PaypalError';
import AccountSuccess from './routes/AccountSuccess';
import AccountExpired from './routes/AccountExpired';

import { ENV } from './client';

const App: React.FC = () => {
  console.log(process.env);
  return (
    <ApolloProvider client={client}>
      <StripeProvider
        apiKey={process.env.NODE_ENV === 'development' ? ENV.dev.STRIPE : ENV[process.env.REACT_APP_ENV || 'production'].STRIPE}>
        <Router>
          <Home path="/" />
          <Card path="/card" />
          <PaypalSuccess path="/paypal-success" />
          <PaypalError path="/paypal-error" />
          <AccountSuccess path="/success" />
          <AccountExpired path="/expired" />
        </Router>
      </StripeProvider>
    </ApolloProvider>
  );
};

export default App;
