import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

import Logo from '../../images/logo.png';
import Expired from '../../images/expired.svg';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fde3d5;
`;

const AppContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px 24px;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const ErrorMessage = styled.p`
  color: #9e182f;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: 0;
`;

const LogoImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 24px;
`;

const ExpiredImage = styled.img`
  width: 100%;
  max-width: 240px;
  margin-bottom: 48px;
`;

const PaypalErrorPage = ({ redirectUrl, accountId }) => {
  return (
    <Wrapper>
      <AppContainer>
        <LogoImage src={Logo} />
        <ExpiredImage src={Expired} />
        <ErrorMessage>Deine Zahlung wurde abgebrochen. Bitte schließe das Fenster und wähle ein anderes Zahlungsmittel.</ErrorMessage>
      </AppContainer>
    </Wrapper>
  );
};

interface PaypalErrorProps extends RouteComponentProps {}

const PaypalError: React.FC<PaypalErrorProps> = () => {
  const [isDone, setIsDone] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [accountId, setAccountId] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirectUrl');
    const accountId = urlParams.get('accountId');
    setRedirectUrl(redirectUrl);
    setAccountId(accountId);
    setIsDone(true);
  }, []);

  if (!isDone) return null;

  return <PaypalErrorPage redirectUrl={redirectUrl} accountId={accountId} />;
};

export default PaypalError;
