import React, { useState, useEffect, KeyboardEvent } from 'react';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FaTrash } from 'react-icons/fa';
import { gql } from 'apollo-boost';
import find from 'lodash/find';

import Logo from '../../images/logo.png';
import AmexLogo from '../../images/brands/amex.png';
import CardLogo from '../../images/brands/card.png';
// import DinersClubLogo from '../../images/brands/diners_club.png';
// import DiscoverLogo from '../../images/brands/discover.png';
// import JCBLogo from '../../images/brands/jcb.png';
import MastercardLogo from '../../images/brands/mastercard.png';
import VisaLogo from '../../images/brands/visa.png';
import Loading from '../../images/loading.png';

import {
  LoadingContainer,
  LoadingImage,
  Container,
  LogoImage,
  IntroText,
  CardWrapper,
  NewCardWrapper,
  NewCardSelection,
  SelectedCircle,
  SelectionCircle,
  CardDate,
  CardImage,
  CardLast4,
  CardText,
  EntryWrapper,
  BuyButton,
} from './styles';

const PAYMENT_METHODS = gql`
  query PAYMENT_METHODS($customerId: String!) {
    paymentMethods(customerId: $customerId) {
      id
      defaultMethod
      last4
      brand
      expMonth
      expYear
    }
  }
`;
const CREATE_PAYMENT_METHOD = gql`
  mutation CREATE_PAYMENT_METHOD($customerId: String!, $token: String!) {
    createPaymentMethod(customerId: $customerId, token: $token) {
      id
    }
  }
`;
const UPDATE_PAYMENT_METHOD = gql`
  mutation UPDATE_PAYMENT_METHOD($customerId: String!, $sourceId: String!, $defaultMethod: Boolean) {
    updatePaymentMethod(customerId: $customerId, sourceId: $sourceId, defaultMethod: $defaultMethod) {
      id
    }
  }
`;
const DELETE_PAYMENT_METHOD = gql`
  mutation DELETE_PAYMENT_METHOD($customerId: String!, $sourceId: String!) {
    deletePaymentMethod(customerId: $customerId, sourceId: $sourceId)
  }
`;

interface FormProps {
  stripe?: ReactStripeElements.StripeProps;
  customerId?: string;
  redirectUrl?: string;
}

const getImage = (brand) => {
  switch (brand) {
    case 'Visa':
      return VisaLogo;
    case 'MasterCard':
      return MastercardLogo;
    case 'American Express':
      return AmexLogo;
    default:
      return CardLogo;
  }
};

const Form: React.FC<FormProps> = ({ stripe, redirectUrl, customerId }) => {
  const [showLoading, setShowLoading] = useState(true);
  const [firstLoadDone, setFirsLoadDone] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [createPaymentMethod] = useMutation(CREATE_PAYMENT_METHOD);
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);
  const [deletePaymentMethod] = useMutation(DELETE_PAYMENT_METHOD);
  const { loading, refetch, error, data } = useQuery(PAYMENT_METHODS, {
    variables: { customerId },
  });

  const submit = async () => {
    try {
      setShowLoading(true);
      if (selectedOption === 'new') {
        const { error, token } = await stripe.createToken();
        if (error) {
          console.log(error);
          setShowLoading(false);
          return;
        }

        const { data: createData } = await createPaymentMethod({
          variables: {
            customerId,
            token: token.id,
          },
        });
        await setSelectedOption(createData.createPaymentMethod.id);
      } else {
        await updatePaymentMethod({
          variables: {
            customerId,
            sourceId: selectedOption,
            defaultMethod: true,
          },
        });
      }
      await refetch();
      // @ts-ignore
      if (window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'CreditCard' }));
      } else {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      setShowLoading(false);
      console.log(error);
    }
  };

  const deleteCard = async (selectedCard) => {
    try {
      setShowLoading(true);
      await deletePaymentMethod({
        variables: {
          customerId,
          sourceId: selectedCard,
        },
      });
      await refetch();
      setSelectedOption('');
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      console.log(error);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, id: string) => {
    if (event.keyCode === 13) {
      setSelectedOption(id);
    }
  };

  useEffect(() => {
    if (!loading && !error) {
      const id = setTimeout(() => {
        setShowLoading(false);
        setFirsLoadDone(true);
      }, 1000);
      if (!firstLoadDone || !selectedOption) {
        console.log('hier');
        const defaultPaymentMethod = find(data.paymentMethods, ({ defaultMethod }) => defaultMethod);
        if (defaultPaymentMethod) {
          setSelectedOption(defaultPaymentMethod.id);
        } else {
          setSelectedOption('new');
        }
      }
      return () => clearTimeout(id);
    }
  }, [loading, error, firstLoadDone, data, selectedOption]);

  return (
    <Container role="radiogroup">
      {showLoading || loading ? (
        <LoadingContainer>
          <LoadingImage src={Loading} />
        </LoadingContainer>
      ) : null}
      <LogoImage src={Logo} />
      {data && data.paymentMethods.length > 0 ? (
        <IntroText>Hinterlege ein gültiges Zahlungsmittel oder wähle eine bereits bestehende Methode aus der Liste aus.</IntroText>
      ) : (
        <IntroText>Hinterlege ein gültiges Zahlungsmittel.</IntroText>
      )}
      {data &&
        data.paymentMethods.map(({ id, brand, last4, expMonth, expYear, defaultMethod }) => {
          return (
            <CardWrapper
              key={id}
              tabIndex={0}
              onKeyDown={(event) => handleKeyDown(event, id)}
              onClick={() => setSelectedOption(id)}
              role="radio"
              isActive={selectedOption === id}
              aria-checked={selectedOption === id}>
              <SelectionCircle>{selectedOption === id ? <SelectedCircle /> : null}</SelectionCircle>
              <CardImage src={getImage(brand)} />
              <CardLast4>{last4}</CardLast4>
              <CardDate>{`${expMonth}/${expYear}`}</CardDate>
              {defaultMethod ? null : (
                <FaTrash color="#DC3030" width={24} height={24} style={{ marginLeft: 16 }} onClick={() => deleteCard(id)} />
              )}
            </CardWrapper>
          );
        })}
      <NewCardWrapper
        tabIndex={0}
        onKeyDown={(event) => handleKeyDown(event, 'new')}
        onClick={() => setSelectedOption('new')}
        role="radio"
        isActive={selectedOption === 'new'}
        aria-checked={selectedOption === 'new'}>
        <NewCardSelection>
          <SelectionCircle>{selectedOption === 'new' ? <SelectedCircle /> : null}</SelectionCircle>
          <CardText>Neue Kreditkarte eingeben</CardText>
        </NewCardSelection>
        {selectedOption === 'new' ? (
          <EntryWrapper>
            <CardElement
              style={{
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  fontFamily: 'Open Sans, sans-serif',
                  letterSpacing: '0.025em',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#c23d4b',
                },
              }}
            />
          </EntryWrapper>
        ) : null}
      </NewCardWrapper>
      <BuyButton disabled={!selectedOption} onClick={submit}>
        {selectedOption === 'new' ? 'Neue Kreditkarte hinterlegen' : 'Übernehmen'}
      </BuyButton>
    </Container>
  );
};

export default injectStripe(Form);
