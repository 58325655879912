import ApolloClient from 'apollo-boost';

export const ENV = {
  dev: {
    // apiUrl: 'http://172.20.0.108:4000',
    apiUrl: 'http://192.168.0.52:4000',
    STRIPE: 'pk_test_06q88UUjmIHv65McNPzyQjJr00krggifww',
    // apiUrl: 'http://172.20.15.111:4000',
  },
  staging: {
    apiUrl: 'https://api.dev.dropfriends.com',
    STRIPE: 'pk_test_06q88UUjmIHv65McNPzyQjJr00krggifww',
  },
  production: {
    apiUrl: 'https://api.dropfriends.com',
    STRIPE: 'pk_live_pGaJFfbxsUhigWv5AqCjadCj00YredKzgS',
  },
};

export const client = new ApolloClient({
  uri: process.env.NODE_ENV === 'development' ? ENV.dev.apiUrl : ENV[process.env.REACT_APP_ENV || 'production'].apiUrl,
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );

    if (networkError) alert(`[Network error]: ${networkError}`);
  },
});
